import React from "react";
import ThemeContext from "../../context/ThemeContext";
import { useContext } from "react";

const Services = ({ data }) => {
  const themeState = useContext(ThemeContext);

  const stackCursorUp = (e) => {
    themeState.changeCursorIndex("1");
  };
  const stackCursorDown = (e) => {
    themeState.changeCursorIndex("-1");
  };

  const resetCursor = (e) => {
    themeState.toggleCursorBlendMode();
    themeState.toggleCursorBackground("transparent");
    themeState.scaleCursor(1);
  };

  const shrinkCursor = (e) => {
    themeState.toggleCursorBlendMode("difference");
    themeState.toggleCursorBackground("#fff");
    themeState.shrinkCursor(0.5);
  };

  return (
    <div className="md:max-w-[1440px] md:mx-auto mt-[70px] md:mt-[311px] box-border overflow-x-hidden">
      <section className="services mx-[15px] md:mx-[34px]">
        <div className="md:w-[787px] md:mx-auto flex flex-col-reverse md:flex-col">
          <div>
            <h4 className="uppercase font-bold mt-2.5 md:mt-0">
              {data.title.text}
            </h4>
            <div
              className="prose mb-2 w-full md:w-[516px]"
              dangerouslySetInnerHTML={{ __html: data.description.html }}
            />
            <a
              onMouseEnter={shrinkCursor}
              onMouseLeave={resetCursor}
              href={`${data.site_url}`}
              target="_blank"
              rel="noreferrer"
              className="mt-2"
            >
              <span className="backdrop">{data.site_url}</span>
            </a>
          </div>
          <div className="md:mt-[29px]">
            <div
              className="aspect-video"
              // onMouseEnter={stackCursorDown}
              // onMouseLeave={stackCursorUp}
            >
              <video
                src={data.mr_video.url}
                muted
                autoPlay
                loop
                playsInline
                data-wf-ignore="true"
                data-object-fit="cover"
                className="w-full h-full object-cover"
              ></video>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
