import React from "react";
import ThemeContext from "../../context/ThemeContext";
import { useContext } from "react";

const DigitalShowroom = ({ data }) => {
  const themeState = useContext(ThemeContext);

  // const stackCursorUp = (e) => {
  //   themeState.changeCursorIndex("1");
  // };
  // const stackCursorDown = (e) => {
  //   themeState.changeCursorIndex("-1");
  // };

  const resetCursor = (e) => {
    themeState.toggleCursorBlendMode();
    themeState.toggleCursorBackground("transparent");
    themeState.scaleCursor(1);
  };

  const shrinkCursor = (e) => {
    themeState.toggleCursorBlendMode("difference");
    themeState.toggleCursorBackground("#fff");
    themeState.shrinkCursor(0.5);
  };

  return (
    <div className="md:max-w-[1440px] mt-[70px] md:mx-auto md:mt-[271px]">
      <section className="digit-showroom mx-[17px] md:mx-[34px]">
        <div
          className="aspect-video md:w-[787px] md:h-[524px] md:mx-auto"
          // onMouseEnter={stackCursorDown}
          // onMouseLeave={stackCursorUp}
        >
          <video
            src={data.digital_showroom_video.url}
            className="w-full h-full object-cover"
            autoPlay
            muted
            loop
            playsInline
            data-wf-ignore="true"
            data-object-fit="cover"
          ></video>
        </div>
        <div className="md:w-[787px] md:mx-auto">
          <h4 className="uppercase mt-2">{data.title.text}</h4>
          <div
            className="prose mb-2"
            dangerouslySetInnerHTML={{
              __html: data.description.html,
            }}
          ></div>
          <a
            onMouseEnter={shrinkCursor}
            onMouseLeave={resetCursor}
            href={data.url.url}
            className="mt-2"
            target="_blank"
            rel="noreferrer"
          >
            <span className="backdrop">{data.url.url}</span>
          </a>
        </div>
      </section>
    </div>
  );
};

export default DigitalShowroom;
