import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";

const ShowRoom = ({ data }) => {
  const themeState = useContext(ThemeContext);

  const changeBackground = (e) => {
    removeBorder();
    themeState.toggleCursorBackground("#fff");
    // themeState.scaleCursor(3.5, true);
  };
  const resetCursor = (e) => {
    showBorder();
    themeState.toggleCursorBackground("transparent");
    // themeState.scaleCursor(1);
  };

  const removeBorder = () => {
    themeState.toggleCursorBorder();
  };
  const showBorder = () => {
    themeState.toggleCursorBorder("1px solid rgba(255,255,255,1)");
  };

  return (
    <div className="md:max-w-[1440px] md:mx-auto mt-[70px] md:mt-[390px]">
      <section className="showroom mx-[17px] md:mx-[34px] flex flex-col-reverse md:flex-row">
        <div className="max-w-[787px] w-full mx-auto flex flex-col md:flex-row md:items-start md:justify-between">
          <div className="content w-full max-w-md md:max-w-[230px] text-[8px] sm:text-[10px]">
            <h4 className="uppercase mt-2.5 md:mt-0">{data.title.text}</h4>
            <div className="mt-2 uppercase whitespace-pre-wrap space-y-1 tracking-[-0.1px] leading-[9px]">
              {data.address}
            </div>
            <div className="w-full flex items-start justify-between mt-5 space-x-7 md:space-x-0">
              <div
                className="image-container w-full max-w-[65%] md:max-w-[290px] md:hidden"
                // onMouseEnter={changeBackground}
                // onMouseLeave={resetCursor}
              >
                <GatsbyImage
                  image={data.image.gatsbyImageData}
                  alt={data.title.text}
                  className="w-full backdrop h-full"
                  objectFit="contain"
                />
              </div>
              <div className="max-w-[35%] md:max-w-[227px] w-full mt-14 md:mt-[138px]">
                <GatsbyImage
                  image={data.side_image.gatsbyImageData}
                  alt={data.image.alt}
                  className="w-full backdrop h-full"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
          <div
            className="image-container w-full max-w-[290px] md:max-w-[492px] hidden md:block"
            // onMouseEnter={changeBackground}
            // onMouseLeave={resetCursor}
          >
            <GatsbyImage
              image={data.image.gatsbyImageData}
              alt={data.title.text}
              className="w-full backdrop h-full"
              objectFit="contain"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShowRoom;
