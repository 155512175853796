import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";

const Banner = ({ desktopImage, desktopImageSecondary, mobileImage }) => {
  // const themeState = useContext(ThemeContext);

  // const changeBackground = (e) => {
  //   removeBorder();
  //   themeState.toggleCursorBackground("#fff");
  //   // themeState.scaleCursor(3.5, true);
  // };
  // const resetBackground = (e) => {
  //   showBorder();
  //   themeState.toggleCursorBackground("transparent");
  //   // themeState.scaleCursor(1);
  // };

  // const removeBorder = () => {
  //   themeState.toggleCursorBorder();
  // };
  // const showBorder = () => {
  //   themeState.toggleCursorBorder("1px solid rgba(255,255,255,1)");
  // };

  return (
    <div className="min-w-[250px] md:max-w-[1440px] md:mx-auto mt-[20px] md:mt-[46px]">
      <section
        className="banner w-full px-[17px] md:px-[34px]"
        // onMouseEnter={changeBackground} onMouseLeave={resetBackground}
      >
        <GatsbyImage
          image={desktopImage.gatsbyImageData}
          alt={desktopImage.alt || "Hero Image"}
          className="w-full h-full hidden sm:block backdrop"
          objectFit="contain"
          loading="eager"
        />
        {/* <GatsbyImage
          image={desktopImageSecondary.gatsbyImageData}
          alt={desktopImage.alt || "Hero Image"}
          className="w-full h-full hidden sm:block 2xl:hidden backdrop"
          objectFit="contain"
          loading="eager"
        /> */}
        <GatsbyImage
          image={mobileImage.gatsbyImageData}
          alt={mobileImage.alt || "Hero Image"}
          className="w-full h-full block sm:hidden backdrop"
          objectFit="contain"
          loading="eager"
        />
      </section>
    </div>
  );
};

export default Banner;
