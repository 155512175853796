import React from "react";
import Banner from "../components/banner/banner";
import About from "../components/about/about";
import Brands from "../components/brands/brands";
import History from "../components/history/history";
import Services from "../components/services/services";
import DigitalShowroom from "../components/digital-showroom/digitalShowroom";
import ShowRoom from "../components/showroom/showroom";
import { graphql } from "gatsby";
import Contact from "../components/contact";
import { SEO } from "../components/seo";

const Home = ({ data }) => {
  const landingPageData = data.prismicLandingPage.data;
  return (
    <div>
      <SEO
        title="Home - Morch"
        description="MØRCH IS A FASHION SALES AGENCY FOUNDED BY LOTTE MØRCH IN 1996 AND IS ONE OF THE LEADING FASHION AGENCIES IN SCANDINAVIA."
      />
      <Banner
        desktopImage={landingPageData.hero_image_desktop}
        desktopImageSecondary={landingPageData.hero_image_desktop_secondary}
        mobileImage={landingPageData.hero_image_mobile}
      />
      <About about={landingPageData.about.document.data} />
      <Brands brands={landingPageData.brands.document.data} />
      <History />
      <DigitalShowroom data={landingPageData.digital_showroom.document.data} />
      <ShowRoom data={landingPageData.showroom.document.data} />
      <Services data={landingPageData.mr.document.data} />
      <Contact data={landingPageData.contact.document.data} />
    </div>
  );
};

export default Home;

export const query = graphql`
  {
    prismicLandingPage {
      data {
        hero_image_desktop {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          alt
        }
        hero_image_desktop_secondary {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          alt
        }
        hero_image_mobile {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          alt
        }
        about {
          document {
            ... on PrismicAbout {
              id
              data {
                title {
                  text
                }
                description {
                  html
                }
                hero_image {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  alt
                }
              }
            }
          }
        }
        brands {
          document {
            ... on PrismicBrands {
              data {
                brand {
                  brand_name {
                    text
                  }
                  brand_link
                  brand_image {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    alt
                  }
                }
              }
            }
          }
        }
        digital_showroom {
          document {
            ... on PrismicDigitalShowroom {
              data {
                title {
                  text
                }
                description {
                  html
                }
                digital_showroom_video {
                  url
                }
                url {
                  url
                }
              }
            }
          }
        }
        showroom {
          document {
            ... on PrismicShowroom {
              data {
                title {
                  text
                }
                image {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  alt
                }
                side_image {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                  alt
                }
                address
              }
            }
          }
        }
        mr {
          document {
            ... on PrismicMr {
              data {
                title {
                  text
                }
                description {
                  html
                }
                site_url
                mr_video {
                  url
                }
              }
            }
          }
        }
        contact {
          document {
            ... on PrismicContact {
              data {
                mail
                telefon
                showroom
              }
            }
          }
        }
      }
    }
  }
`;
