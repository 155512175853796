import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";

const Brands = ({ brands }) => {
  const themeState = useContext(ThemeContext);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState({
    id: currentIndex,
    ...brands.brand[currentIndex],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < brands.brand.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(0);
      }
    }, 4000);
    (async () => {
      setSelectedBrand({
        id: currentIndex,
        ...brands.brand[currentIndex],
      });
    })();
    return () => clearInterval(interval);
  }, [currentIndex]);

  // const changeBackground = (e) => {
  //   removeBorder();
  //   themeState.toggleCursorBackground("#fff");
  //   // themeState.scaleCursor(3.5, true);
  // };
  const resetCursor = (e) => {
    // showBorder();
    themeState.toggleCursorBlendMode();
    themeState.toggleCursorBackground("transparent");
    themeState.scaleCursor(1);
  };

  const shrinkCursor = (e) => {
    themeState.toggleCursorBlendMode("difference");
    themeState.toggleCursorBackground("#fff");
    themeState.shrinkCursor(0.5);
  };

  // const removeBorder = () => {
  //   themeState.toggleCursorBorder();
  // };
  // const showBorder = () => {
  //   themeState.toggleCursorBorder("1px solid rgba(255,255,255,1)");
  // };

  return (
    <div className="min-w-[250px] md:max-w-[1440px] md:mx-auto mt-[70px] md:mt-[261px]">
      <section className="brands flex mx-[17px] md:mx-[34px]">
        <div className="content md:w-[288px] md:ml-[140px] flex">
          <div className="items">
            <h4 className="uppercase">Brands</h4>
            <ul className="mt-2 min-w-[130px]">
              {brands.brand.map((item, index) => {
                return (
                  <li
                    className="uppercase"
                    key={index}
                    onMouseEnter={shrinkCursor}
                    onMouseLeave={resetCursor}
                  >
                    <button
                      className={`bg-transparent text-left ${
                        selectedBrand.id === index && "line-through"
                      }`}
                      onClick={() => setCurrentIndex(index)}
                    >
                      {item.brand_name.text}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div
          className="image-container h-full w-full md:h-[572px] md:w-[407px] ml-0 mt-[18px] md:mt-0"
          // onMouseEnter={changeBackground}
          // onMouseLeave={resetCursor}
        >
          <GatsbyImage
            image={selectedBrand.brand_image.gatsbyImageData}
            alt={selectedBrand.brand_name.text}
            className="h-full w-full backdrop"
            objectFit="contain"
          />
          <div className="text-right">
            <a
              href={selectedBrand.brand_link}
              target="_blank"
              className="cursor-pointer no-underline"
              onMouseEnter={shrinkCursor}
              onMouseLeave={resetCursor}
            >
              <span className="lowercase leading-5 text-[8px] md:text-[10px]">
                {selectedBrand.brand_link}
              </span>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brands;
