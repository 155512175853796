import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";

const About = ({ about }) => {
  // const themeState = useContext(ThemeContext);

  // const changeBackground = (e) => {
  //   removeBorder();
  //   themeState.toggleCursorBackground("#fff");
  //   // themeState.scaleCursor(3.5, true);
  //   // themeState.showBorder(false);
  // };
  // const resetBackground = (e) => {
  //   showBorder();
  //   // themeState.showBorder(true);
  //   themeState.toggleCursorBackground("transparent");
  //   // themeState.scaleCursor(1);
  // };

  // const removeBorder = () => {
  //   themeState.toggleCursorBorder();
  // };
  // const showBorder = () => {
  //   themeState.toggleCursorBorder("1px solid rgba(255,255,255,1)");
  // };

  return (
    <div className="min-w-[250px] md:max-w-[1440px] md:mx-auto mt-[70px] md:mt-[261px]">
      <section className="about mx-[17px] md:mx-[34px] flex flex-col-reverse md:flex-row">
        <div
          className="image-container md:ml-auto w-full max-w-[290px] md:max-w-[519px] md:h-[659px] mt-4 md:mt-0"
          // onMouseEnter={changeBackground} onMouseLeave={resetBackground}
        >
          <GatsbyImage
            image={about.hero_image.gatsbyImageData}
            alt={about.hero_image.alt || "About US Image"}
            className="w-full h-full backdrop"
            objectFit="contain"
          />
        </div>

        <div className="content md:ml-12 md:mr-[70px]">
          <h4 className="uppercase">{about.title.text}</h4>
          <div
            className="mt-2 md:max-w-[305px] prose"
            dangerouslySetInnerHTML={{
              __html: about.description.html,
            }}
          ></div>
        </div>
      </section>
    </div>
  );
};

export default About;
