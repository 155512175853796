import React from "react";
import ThemeContext from "../../context/ThemeContext";
import { useContext } from "react";

const History = () => {
  const themeState = useContext(ThemeContext);

  const changeCursor = (e) => {
    // themeState.scaleCursor(3.5, true);

    // themeState.toggleCursorBlendMode();
    // themeState.toggleCursorBorder('1px solid rgba(0,0,0,1');
  };
  const resetCursor = (e) => {
    // themeState.scaleCursor(1);
    
    // themeState.toggleCursorBlendMode("difference");
    // themeState.toggleCursorBorder('1px solid rgba(255,255,255,1');
  };

  return (
    <section
      className="history relative mt-[70px] md:mt-[317px] h-[396px] md:h-[781px]"
      onMouseEnter={changeCursor}
      onMouseLeave={resetCursor}
    >
      <div className="flex justify-center items-center w-full h-full">
        <p className="uppercase font-bold">
          copenhagen fashion agency since 1996
        </p>
      </div>
    </section>
  );
};

export default History;
